import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Text } from '@chakra-ui/core';
import React from 'react';

const ConfirmDialog = ({
  isOpen, onClose, onClick, title, message, noText, yesText,
}) => {
  const cancelRef = React.useRef();
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {title}
        </AlertDialogHeader>

        <AlertDialogBody>
          {message}
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            {noText}
          </Button>
          <Button variantColor="cyan" onClick={onClick} ml={3}>
            {yesText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
const useConfirmDialog = (action, title, message, noText = 'No', yesText = 'Si') => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const onClick = () => {
    onClose();
    action();
  };
  return {
    confirmDialog: (
      <ConfirmDialog
        isOpen={isOpen}
        onClose={onClose}
        onClick={onClick}
        title={title}
        message={message}
        noText={noText}
        yesText={yesText}
      />
    ),
    open: onOpen,
  };
};

export {
  useConfirmDialog,
};

export default ConfirmDialog;
