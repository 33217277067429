import React from "react";
import { Alert, AlertIcon, Box, Button, Flex, PseudoBox, Text } from "@chakra-ui/core"
import { AiFillLike } from "react-icons/ai";
import { voteBurger } from "../../util/firebaseFunctions"
import { useConfirmDialog } from "../dialogs/ConfirmDialog";
import { useDialogSurvey } from "../dialogs/DialogSurvey";
import { isValidFingerprint, useFingerPrint } from "../../util/fingerprint";
// import {
//   actions,
//   GlobalDispatchContext
// } from "../../context/GlobalContextProvider"

const TheButton = ({
  position, color, hoverColor, disabled, invalid, onClick, ...props
}) => {
  const action = () => {
    if (!disabled && !invalid) {
      onClick();
    }
  };
  return (
    <PseudoBox
      {...props}
      cursor={disabled || invalid ? 'not-allowed' : 'pointer'}
      backgroundColor={ disabled || invalid ? 'gray.200' : color}
      color={invalid ? 'gray.400' : 'white'}
      py={3}
      px={2}
      mx={2}
      borderRadius={4}
      textAlign="center"
      onClick={action}
      _hover={{
        backgroundColor: invalid || disabled ? 'gray.200' : hoverColor,
        color: invalid || disabled ? 'gray.400' : 'white',
      }}
    >
      <Text fontWeight="bold" fontSize="2xl" lineHeight={1}>{position}</Text>
    </PseudoBox>
  );
};

const ButtonVote = ({ user, burger, states }) => {
  // const dispatch = React.useContext(GlobalDispatchContext);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [voteNumber, setVoteNumber] = React.useState(null);
  const {
    dialogSurvey, setVisible:setVisibleSurvey, notVotationOpen, tasted,
  } = useDialogSurvey(burger, user);
  const doTaste = () => {
    setVisibleSurvey(true);
  };
  const currentFingerPrint = useFingerPrint();
  const doVote = () => {
    if (voteNumber === null) {
      return;
    }
    setLoading(true);
    setError(null);
    isValidFingerprint(currentFingerPrint)
    .then((result) => {
      if (result.isValid) {
        voteBurger(burger, voteNumber, user, currentFingerPrint, result.isNew)
        .then(() => {
          setTimeout(() => setVisibleSurvey(true), 200);
        })
        .catch(() => {
          setError('Ups! Algo pasó registrando tu voto, intentalo de nuevo.');
        })
        .finally(() => {
          setLoading(false);
        });
      } else {
        setError(result.message);
        setLoading(false);
      }
    })
    .catch(() => {
      setError('Ups! Algo pasó registrando tu voto, intentalo de nuevo.');
      setLoading(false);
    });
  }
  const { confirmDialog, open } = useConfirmDialog(doTaste, '¿Probaste esta hamburguesa?', '¡Márcala como "Probada" en tu lista si ya lo hiciste!');
  const { confirmDialog:confirmDialogVote, open:openVote } = useConfirmDialog(
    doVote,
    'Confirmar Voto',
    `¿Confirmas que deseas votar por "${burger.name}" como mejor hamburguesa del RoldaBurger 2021?`,
  );
  const disabled = notVotationOpen || currentFingerPrint === null;
  const setTheVote = (number) => {
    if (disabled) {
      return;
    }
    setVoteNumber(number);
    openVote();
  };
  if (disabled) {
    return (
      <Button leftIcon={AiFillLike} mt={4} variantColor="orange" variant="solid" isDisabled>
        {'La votación no está activa'}
      </Button>
    );
  }
  return (
    <>
      {error && (
        <Alert my={4} status="warning">
          <AlertIcon />
          {error}
        </Alert>
      )}
      <Flex mt={4} mb={4} spacing={2} justifyContent="center" alignItems="flex-end">
        <TheButton
          invalid={states.first && !states.electedFirst}
          disabled={loading || states.first}
          color="white"
          p={2}
          textAlign="center"
          onClick={() => setTheVote(1)}
          position="Votar por esta hamburguesa"
          color="red.500"
          hoverColor="red.600"
        />
      </Flex>
      <Text textAlign="center">{states.message}</Text>
      {tasted !== null && (
        <Box textAlign="center">
          <Button
            isLoading={loading}
            mt={4}
            variantColor={tasted ? 'gray' : 'cyan'}
            variant="solid"
            onClick={() => {
              open();
            }}
            isDisabled={tasted}
          >
            {tasted ? 'Ya la probé :)' : 'Aún no la he probado :('}
          </Button>
        </Box>
      )}
      {confirmDialog}
      {dialogSurvey}
      {confirmDialogVote}
    </>
  );
};

export default ButtonVote;
