import React from "react";
import { Image, Box, SimpleGrid, Flex } from "@chakra-ui/core";

const BurguerHeader = () => {
  return (
    <Flex
      align="center"
      justify="center"
      py={0}
    >
      <Box mt={10} mb={4} textAlign={["center", "center", "left"]}>
          <Image
            d="inline-block"
            w="auto"
            h={["80px", "120px", "150px"]}
            src="/images/rolda-burger/logo-roldaburger.png"
            alt="Rolda Burguer"
          />
      </Box>
    </Flex>
  );
};

export default BurguerHeader;
