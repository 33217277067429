import {
  AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader, AlertDialogOverlay, Button, Flex, Text,
} from '@chakra-ui/core';
import StarRatings from 'react-star-ratings';
import React from 'react';
import { taste, useIsVotationOpen, useTasted } from '../../util/burgerUtils';

const DialogSurvey = ({
  visible, setVisible, tasted, notVotationOpen, burger, user,
}) => {
  const cancelRef = React.useRef();
  const [rating, setRating] = React.useState(0);
  const setRatingAction = () => {
    if (tasted === false || !notVotationOpen) {
      taste(burger, user, rating).then(() => {
          setVisible(false);
        })
        .catch(() => setVisible(false));
    }
  };
  return (
    <AlertDialog
      isOpen={visible}
      leastDestructiveRef={cancelRef}
      onClose={() => setVisible(false)}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          CALIFICA ESTE NEGOCIO
        </AlertDialogHeader>

        <AlertDialogBody>
          <Text>¡Ayúdanos a mejorar! De 1 a 5, ¿cuántas estrellas le das a este negocio por sus protocolos de bioseguridad?</Text>
          <Flex mt={4} alignContent="center" justifyContent="center">
            <StarRatings
              rating={rating}
              starRatedColor="#1D8BB0"
              starHoverColor="#1D8BB0"
              starDimension="30px"
              changeRating={setRating}
              numberOfStars={5}
              name='rating'
            />
          </Flex>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={() => setVisible(false)}>
            Cancelar
          </Button>
          <Button variantColor="cyan" onClick={setRatingAction} ml={3}>
            Calificar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const useDialogSurvey = (burger, user) => {
  const tasted = useTasted(burger, user);
  const notVotationOpen = useIsVotationOpen();
  const [visible, setVisible] = React.useState(false);
  return {
    dialogSurvey: (
      <DialogSurvey
        burger={burger}
        notVotationOpen={notVotationOpen}
        setVisible={setVisible}
        tasted={tasted}
        user={user}
        visible={visible}
      />
    ),
    tasted,
    setVisible: (val) => {
      if (tasted !== false) {
        setVisible(false);
        return;
      }
      setVisible(val);
    },
    notVotationOpen,
    visible,
  };
};

export {
  useDialogSurvey,
};

export default DialogSurvey;
