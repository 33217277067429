import React from "react";
import {
  SlideIn,
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  SimpleGrid,
  Box,
  Tag,
  Text,
  Button,
  ButtonGroup,
} from "@chakra-ui/core";
import MaclicImage from "../images/maclicImage";
import MarkDown from "../MarkDown";
import {
  SecondaryHeading,
} from "../headings";
import { useColorWithoutMode } from "../../util/colorMode";
import { Link } from "gatsby-plugin-intl";
import BurgerPlace from "./burgerPlace";
import ButtonVote from "./buttonVote";
import { FaFacebookF, FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getBurgerURL } from "../../util/burgerUtils";
// import StoreButton from "../footer/StoreButton";

const BASE_LINK = `https://web.maclic.co/es/rolda-burger/`;
const BASE_FACEBOOK_LINK = `https://www.facebook.com/sharer/sharer.php?u=${BASE_LINK}`;

const BurgerPopup = ({
  node, setNode, user, firebase, states,
}) => {
  const { color } = useColorWithoutMode("white", "gray.600");
  const [copied, setCopied] = React.useState(false);
  const setNotCopied = React.useCallback(() => {
    setTimeout(() => setCopied(false), 5000);
  }, []);
  return (
    <SlideIn in={!!node}>
      {styles => (
        <Modal
          onClose={() => {
            window.location.hash = '';
            setNode(null);
          }}
          isOpen={true}
        >
          <ModalOverlay opacity={styles.opacity} />
          <ModalContent
            width="100%"
            maxWidth={[
              "100%", // base
              "500px", // 480px upwards
              "700px", // 768px upwards
              "900px", // 992px upwards
            ]}
            {...styles}
          >
            <ModalCloseButton bg={color} _hover={{ background: color }} />
            <ModalBody p={0}>
              {!!node && (
                <>
                  <SimpleGrid
                    columns={[1, 1, 2, 2]}
                    spacing={4}
                  >
                    <Box>
                      <MaclicImage size="pilot" src={node.image} />
                    </Box>
                    <Box py={8} px={4}>
                      <Box>
                        <Box float="left" d="inline-block" w={[50, 60]} h={[50, 60]}>
                          <MaclicImage size="pilot" src={node.logo} />
                        </Box>
                        <Box pl={2} d="inline-block" minHeight={[50, 60]}>
                          {!!node.place && (
                            <Box>
                              <Link to={node.place.fields.slug}>
                                <Tag
                                  minHeight="1rem"
                                  rounded="full"
                                  variantColor="red"
                                  size="sm"
                                  textAlign="center"
                                >
                                  {node.place.name}
                                </Tag>
                              </Link>
                            </Box>
                          )}
                          <SecondaryHeading as="h2" fontSize={["2xl"]} mt={0} mb={0}>
                            {node.name}
                          </SecondaryHeading>
                        </Box>
                      </Box>
                      {!!user && (
                        <>
                          <ButtonVote
                            firebase={firebase}
                            burger={node}
                            user={user}
                            states={states}
                          />
                          <Box my={4}>
                            <Text as="h5">Comparte tu voto</Text>
                            <ButtonGroup mt={2} spacing={4}>
                              <Button
                                mr={4}
                                leftIcon={FaFacebookF}
                                variantColor="facebook"
                                variant="solid"
                                as="a"
                                target="_blank"
                                href={`${BASE_FACEBOOK_LINK}${getBurgerURL(node.name)}`}
                              >
                                Facebook
                              </Button>
                              <CopyToClipboard
                                text={`${BASE_LINK}${getBurgerURL(node.name)}`}
                                onCopy={() => {
                                  setCopied(true);
                                  setNotCopied();
                                }}
                              >
                                <Button
                                  leftIcon={FaCopy}
                                  variant="solid"
                                  isDisabled={copied}
                                >
                                  {copied ? `Enlace copiado` : `Copiar enlace`}
                                </Button>
                              </CopyToClipboard>
                            </ButtonGroup>
                          </Box>
                        </>
                      )}
                      <MarkDown md={node.description} />
                    </Box>
                  </SimpleGrid>
                  {!!node.place && (
                    <Box>
                      <BurgerPlace place={node.place} />
                    </Box>
                  )}
                </>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </SlideIn>
  );
};


export default BurgerPopup;
