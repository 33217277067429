import React from "react";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import BurgerPageResults from "../../components/burguer/burgerPageResults";
import BurgerPage from "../../components/burguer/burgerPage";
import { useRegisterScreen } from "../../util/firebaseFunctions"

const RoldaBurguer = ({ data, pageContext, navigate, location }) => {
  const { nodes } = data.allBurger;
  const { id } = pageContext;
  let node;
  if (id) {
    node = nodes.find(item => item.id === id);
  }
  const [results, setResults] = React.useState(false);
  React.useEffect(() => {
    if (location.search.indexOf('results') !== -1) {
      setResults(true);
    } else {
      setResults(false);
    }
  }, [location.search]);
  useRegisterScreen('Rolda Burger');
  const title = "Rolda Burger 2021";
  const description = "Batalla de las hamburguesas Roldanillo. ¡Elijamos juntos la mejor hamburguesa de Roldanillo!";
  const image = "/images/burger2/defaultimage.png";
  const path = '/es/rolda-burger';
  return (
    <>
      <SEO
        description={description}
        title={title}
        pathname={path}
        image={image}
      />
      {results ? (
        <BurgerPageResults
          burgers={nodes}
          burger={node}
          navigate={navigate}
        />
      ) : (
        <BurgerPage
          burger={node}
          burgers={nodes}
          navigate={navigate}
        />
      )}
    </>
  );
};

export const query = graphql`
  query {
    allBurger {
      nodes {
        place {
          name
          links {
            label
            type
            url
          }
          venues {
            address
            location {
              lat
              lng
            }
            name
            phones {
              number
              phone
              whatsapp
            }
            schedule {
              friday {
                start
                end
              }
              holiday {
                start
                end
              }
              monday {
                start
                end
              }
              saturday {
                start
                end
              }
              sunday {
                start
                end
              }
              thursday {
                start
                end
              }
              tuesday {
                start
                end
              }
              wednesday {
                start
                end
              }
            }
          }
          fields {
            slug
          }
        }
        name
        logo
        id
        image
        description
      }
    }
  }
`;

export default RoldaBurguer;
