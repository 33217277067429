import React from "react";
import { SecondaryHeading } from "../headings";
import LinkList from "../links/linkList"
import { useLinks } from "../../util/places";
import { Box } from "@chakra-ui/core";
import Schedule from "../places/schedule";
import {
  FiMapPin
} from "react-icons/fi";
import VenueMap from "../venues/venueMap"

const BurgerPlace = ({ place }) => {
  const venue = place ? place.venues[0] : null;
  const links = useLinks(place, venue);
  return place && (
    <Box textAlign="center" px={4} pb={8}>
      <SecondaryHeading fontWeight={500} as="h3" fontSize="xl" mt={[2, 2, 8]} mb={2}>
        {`Sobre ${place.name}`}
      </SecondaryHeading>
      <Box d="block">
        {venue.address}
        <Box d="inline" as={FiMapPin} color="red.500" ml={1} />
      </Box>
      <Box mt={2} d="block">
        <Schedule ml={[0, 4, 8, 8]} venue={venue} collapse />
      </Box>
      <LinkList textAlign="center" d="table" mx="auto" links={links} />
      <Box mx="auto" w="100%" maxWidth={500} mt={4} className="venueMap">
        <VenueMap venue={venue} />
      </Box>
    </Box>
  );
};

export default BurgerPlace;
