import React from "react";
import * as PropTypes from "prop-types";
import { Box, Flex } from "@chakra-ui/core";
import Header from "../header";
import Footer from "../footer";

const BurguerLayout = ({
  children, backgroundColor, headContent, ...rest
}) => {
  return (
    <>
      <Header/>
      {!!headContent && (
        <Box
          bg="black"
          backgroundImage="url('/images/rolda-burger/rbbackground.png')"
          pt={16}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center top"
        >
          {headContent}
        </Box>
      )}
      <Flex align="center" justify="center" mt={!!headContent ? 0 : 16} {...rest}>
        <Box
          m={4}
          pb={8}
          width="100%"
          maxWidth={[
            "100%", // base
            "500px", // 480px upwards
            "700px", // 768px upwards
            "900px", // 992px upwards
          ]}
        >
          {children}
        </Box>
      </Flex>
      <Footer/>
    </>
  );
};

BurguerLayout.defaultProps = {
  headContent: null,
  backgroundColor: null,
};

BurguerLayout.propTypes = {
  headContent: PropTypes.node,
  backgroundColor: PropTypes.string,
};

export default BurguerLayout;
