import React from "react";
import { parse } from "query-string";
import {
  deleteFile, getPilotRecord, registerPilot, uploadFile,
} from "../../util/firebaseFunctions";
import moment from "moment-timezone";
moment.tz.setDefault("America/Bogota");

const useInitialValues = (firebase, user, location) => {
  const [editRecord, setEditRecord] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(null);
  React.useEffect(() => {
    if (firebase && user) {
      const { id } = parse(location.search);
      if (id) {
        setEditRecord(true);
        getPilotRecord(firebase, user.uid, id)
          .then((result) => {
            if (result) {
              setInitialValues(result);
            } else {
              setEditRecord(false);
            }
          })
          .catch((e) => {
            console.warn(e.message);
            setEditRecord(false);
          });
      }
    }
  }, [location.search, firebase, user]);
  return {
    editRecord,
    initialValues,
  };
};

const getFileName = (basePath, fileName) => {
  return `/images/pilots/${basePath}/${fileName.replace(/[^a-zA-Z0-9-_\/.]/g, '')}`;
};

const doUploadFile = (firebase, name, values, setProgress, base, parts) => new Promise(
  (resolve, reject) => {
    if (values[name] !== "") {
      const inputFile = document.getElementById(name).files[0];
      const fileName = getFileName(name, `${values.docNumber}-${inputFile.name}`);
      uploadFile(firebase, fileName, inputFile, (progress) => {
        setProgress(Math.ceil(progress.value/parts) + base);
      }, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    }
  }
);

const deleteImage = async (firebase, value, setProgress) => {
  if (value.startsWith("/images")) {
    setProgress(50);
    try {
      await deleteFile(firebase, value);
    } catch (e) {
      console.warn(e.message);
    } finally {
      setProgress(-1);
    }
  }
};

const doSubmit = async (firebase, user, values, setProgress) => {
  const newValues = { ...values };
  if (!values.photo.startsWith("/images")) {
    newValues.photo = await doUploadFile(firebase,"photo", values, setProgress, 0, 3);
  }
  if (!values.insurancePhoto.startsWith("/images")) {
    newValues.insurancePhoto = await doUploadFile(firebase,"insurancePhoto", values, setProgress, 33, 3);
  }
  if (values.passportPhoto !== "" && !values.passportPhoto.startsWith("/images")) {
    newValues.passportPhoto = await doUploadFile(firebase,"passportPhoto", values, setProgress, 66, 3);
  }
  Object.keys(newValues).forEach((key) => {
    if (!!newValues[key] && newValues[key] !== "" && key.toLowerCase().indexOf("date") !== -1) {
      newValues[key] = moment(newValues[key]).toISOString();
    }
  });
  newValues.createdBy = user.uid;
  await registerPilot(newValues, firebase);
};

const useUser = (firebase) => {
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    let unsubscribe;
    if (firebase) {
      unsubscribe = firebase.auth()
        .onAuthStateChanged((u) => setUser(u));
    }
    return () => !!unsubscribe && unsubscribe();
  }, [firebase]);
  return user;
};

export {
  useInitialValues,
  doSubmit,
  useUser,
  deleteImage,
};