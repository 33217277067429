import React from "react";
import { Box, SimpleGrid, Heading, Text } from "@chakra-ui/core";
import BurgerVotes from "./burgerVotes";
import BurgerComponent from "./burgerComponent";

const getPercentaje = (votes, totalVotes) => votes / totalVotes * 100;

const getPrize = (vote, totalVotes) => ({
  ...vote,
  percentaje: getPercentaje(vote.count, totalVotes),
});

const BurgerResults = ({ firebase, nodes, setNode }) => {
  const [prizes, setPrizes] = React.useState(null);
  React.useEffect(() => {
    let unsubscribe;
    if (firebase) {
      const burgers = {};
      nodes.forEach((node) => { burgers[node.id] = node });
      unsubscribe = firebase.firestore()
        .collection('roldaburger2021')
        .onSnapshot((snapShot) => {
          const votes = {};
          let points = 0;
          snapShot.forEach((doc) => {
            const data = doc.data();
            if (data.first) {
              const burger = votes[data.first] || { burger: burgers[data.first], count: 0 };
              burger.count += 3;
              points += 3;
              votes[data.first] = burger;
            }
            if (data.second) {
              const burger = votes[data.second] || { burger: burgers[data.second], count: 0 };
              burger.count += 2;
              points += 2;
              votes[data.second] = burger;
            }
            if (data.third) {
              const burger = votes[data.third] || { burger: burgers[data.third], count: 0 };
              burger.count += 1;
              points += 1;
              votes[data.third] = burger;
            }
          });
          const finalVotes = Object.values(votes).sort((a, b) => a.count < b.count ? 1 : -1);
          let position = 1;
          let count = finalVotes[0].count;
          const resultVotes = [];
          finalVotes.some((vote) => {
            if (vote.count === count) {
              vote.position = position;
            } else {
              position += 1;
              count = vote.count;
              vote.position = position;
            }
            if (position > 3) {
              return true;
            }
            resultVotes.push(getPrize(vote, points));
            return false;
          });
          setPrizes(resultVotes);
        }, (e) => console.warn(e));
    }
    return () => unsubscribe && unsubscribe();
  }, [firebase]);

  return (
    <Box my={4} pb={8}>
      <BurgerVotes
        d="table"
        color="white"
        textAlign="center"
        mx="auto"
        firebase={firebase}
        withBorder={false}
      />
      {prizes && (
        <SimpleGrid
          mt={4}
          color="white"
          mx="auto"
          maxWidth={1024}
          columns={[1, 2, prizes.length % 2 === 0 ? 2 : 3]}
          spacing={4}
        >
          {prizes.map((prize) => (
            <Box key={prize.burger.id} padding={4} bg="gray.900">
              <Box mb={2}>
                <Heading size="2xl" fontWeight={500} d="inline-block" mr={2} >
                  {prize.position}
                </Heading>
                <Box d="inline-block" mr={2}>
                  <Text fontSize="xl">
                    {prize.count}
                  </Text>
                  <Text fontSize="xs">
                    Número de puntos
                  </Text>
                </Box>
                <Box d="inline-block">
                  <Text fontSize="xl">
                    {prize.percentaje.toFixed(2)}
                    {' %'}
                  </Text>
                  <Text fontSize="xs">
                    Porcentaje de puntos
                  </Text>
                </Box>
              </Box>
              <BurgerComponent
                node={prize.burger}
                setNode={setNode}
                firebase={firebase}
                results
              />
            </Box>
          ))}
        </SimpleGrid>
      )}
    </Box>
  )
};

export default BurgerResults;
