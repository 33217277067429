import React from "react";
import * as PropTypes from "prop-types";
import firebase from "../../../plugins/gatsby-plugin-firebase";
import BurgerLayout from "../layout/burguerLayout";
import BurguerHeader from "./header";
import { MainHeading } from "../headings";
import { SimpleGrid } from "@chakra-ui/core";
import BurgerComponent from "./burgerComponent";
import BurgerPopup from "./burgerPopup";
import BurgerResults from "./burgerResults";

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const BurgerPageResults = ({ burgers }) => {
  const [node, setNode] = React.useState(null);
  const handleChangeNode = (theNode) => {
    setNode(theNode);
  };

  const [nodes, setNodes] = React.useState(null);
  React.useEffect(() => {
    setNodes(shuffle(burgers));
  }, [burgers]);

  return (
    <BurgerLayout
      headContent={
        <>
          <BurguerHeader/>
          <BurgerResults firebase={firebase} nodes={burgers} setNode={setNode} />
        </>
      }
    >
      <MainHeading size="xl" mt={4} pt={0}>
        Las hamburguesas competidoras
      </MainHeading>
      {!!nodes && (
        <SimpleGrid columns={[1, 2, 3]} spacing={8}>
          {nodes.map((node) => (
            <BurgerComponent
              key={node.id}
              node={node}
              setNode={setNode}
              firebase={firebase}
              results
            />
          ))}
        </SimpleGrid>
      )}
      <BurgerPopup
        node={node}
        setNode={handleChangeNode}
        firebase={firebase}
      />
    </BurgerLayout>
  );
};

BurgerPageResults.propTypes = {
  burgers: PropTypes.array.isRequired,
  burger: PropTypes.any,
  navigate: PropTypes.func.isRequired,
};

BurgerPageResults.defaultProps = {
  burger: null,
};

export default BurgerPageResults;
