const React = require('react');
const Moment = require("moment-timezone");
const { extendMoment } = require("moment-range");
const firebase = require('../../plugins/gatsby-plugin-firebase').default;

const moment = extendMoment(Moment);
moment.tz.setDefault("America/Bogota");

const getVotationRange = async () => {
  const doc = await firebase.firestore().collection('votations').doc('roldaburger2021').get();
  const votation = doc.data();
  const initial = moment(votation.start.toDate());
  const final = moment(votation.end.toDate());
  return moment.range(initial, final);
};

const isVotationOpen = (range) => {
  const current = moment(firebase.firestore.Timestamp.now().toDate());
  const result =  range && current.within(range);
  return result;
};

const getVotationStatus = (range) => {
  const current = moment(firebase.firestore.Timestamp.now().toDate());
  if (range && current.isBefore(range.start)) {
    return {
      status: 'La votación inicia en:',
      to: new Date(range.start.toISOString()),
    };
  }
  if (range && current.isBefore(range.end)) {
    return {
      status: 'La votación termina en:',
      to: new Date(range.end.toISOString()),
    };
  }
  if (!range) {
    return {
      status: 'Cargando...',
      to: null,
    };
  }
  return {
    status: 'La votación se ha cerrado.',
    to: null,
  };
};

const taste = async (burger, user, rating) => {
  await firebase.firestore()
    .collection('roldaburger2021taste')
    .doc(burger.id)
    .collection('tastes')
    .doc(user.uid)
    .set({rating, tasted: true}, {merge: true});
};

const useTasted = (burger, user) => {
  const [tasted, setTasted] = React.useState(null);
  React.useEffect(() => {
    let unsubscribe;
    if (burger && user) {      
      unsubscribe = firebase.firestore()
        .collection('roldaburger2021taste')
        .doc(burger.id)
        .collection('tastes')
        .doc(user.uid)
        .onSnapshot(
          (snap) => {
            if (snap.exists) {
              setTasted(true);
            } else {
              setTasted(false);
            }
          },
          (err) => {
            setTasted(null);
          },
        );
    } else {
      !!unsubscribe && unsubscribe();
      unsubscribe = null;
      setTasted(null);
    }
    return () => !!unsubscribe && unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [burger, user]);
  return tasted;
};

const useIsVotationOpen = () => {
  const [votationRange, setVotationRange] = React.useState(null);
  React.useEffect(() => {
    getVotationRange()
      .then((range) => {
        setVotationRange(range);
      })
      .catch((err) => {
        console.warn(`Error getting votation range: ${err.message || err}`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return !isVotationOpen(votationRange);
};

const getBurgerURL = (name) => {
  const nameWithoutAccent = name.trim().toLowerCase()
    .normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  return nameWithoutAccent.replace(/ /g,"-").replace(/\//g, "-");
};

module.exports = {
  getVotationStatus,
  getBurgerURL,
  getVotationRange,
  isVotationOpen,
  useTasted,
  useIsVotationOpen,
  taste,
};
