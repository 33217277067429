import React from "react";
import {
  Box, Heading, Text,
} from "@chakra-ui/core";
import Countdown, { zeroPad } from 'react-countdown-now';
import BurgerVotes from "./burgerVotes";
import { getVotationRange, getVotationStatus } from "../../util/burgerUtils";

const UnitTime = ({ value, unit }) => (
  <>
    <Text d="inline-block" mr={1} fontSize={["2xl", "4xl"]} fontWeight={500}>
      {value}
    </Text>
    <Text d="inline-block" mr={4} fontSize="sm" fontWeight={200}>
      {unit}
    </Text>
  </>
);

const BurgerStatus = ({ firebase }) => {
  const [status, setStatus] = React.useState(null);
  const [to, setTo] = React.useState(null);
  React.useEffect(() => {
    if (!to) {
      getVotationRange()
      .then((range) => {
        const res = getVotationStatus(range);
        setStatus(res.status);
        setTo(res.to);
      })
      .catch((err) => console.warn(err));
    }
  }, [to]);

  return !!status && (
    <Box
      color="white"
      textAlign="center"
      pt={4}
      pb={8}
      d="table"
      mx="auto"
    >
      <BurgerVotes firebase={firebase} />
      <Box d="table-cell" px={4}>
        <Heading as="h4" fontWeight={200} size={["md", "lg"]}>
          {status}
        </Heading>
        {!!to && (
          <Countdown
            date={to}
            onComplete={() => {
              setTo(null);
            }}
            renderer={({
               days, hours, minutes, seconds,
             }) => (
              <Box>
                <UnitTime unit="Días" value={days} />
                <UnitTime unit="Horas" value={`${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`} />
              </Box>
            )}
          />
        )}
      </Box>
    </Box>
  );
};

export default BurgerStatus;
