import React from "react";
import {
  Box,
  Button,
  Heading,
  Tag,
  Text,
} from "@chakra-ui/core";
import MaclicImage from "../images/maclicImage";
import { actions, GlobalDispatchContext } from "../../context/GlobalContextProvider";
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { useTasted } from "../../util/burgerUtils";

const BurgerComponent = ({
  node: tempNode, setNode,
  user, results,
}) => {
  const dispatch = React.useContext(GlobalDispatchContext);
  const [node, setTheNode] = React.useState(null);
  React.useEffect(() => {
    setTheNode(tempNode);
  }, [tempNode]);
  const onClick = () => {
    if (results || user) {
      setNode(node);
    } else {
      dispatch({
        type: actions.setDialogMessage,
        message: "Regístrate o inicia sesión antes de votar por tu hamburguesa favorita"
      });
    }
  };
  const tasted = useTasted(node, user);
  return !!node && (
    <Box
      shadow="md"
      borderWidth="1px"
      cursor="pointer"
      onClick={onClick}
    >
      <MaclicImage size="pilot" src={node.image} w={600} h={600} />
      <Box w="100%" py={2} px={2}>
        <Box float="left" d="inline-block" w={50} h={50}>
          <MaclicImage size="pilot" src={node.logo} w={100} h={100} />
        </Box>
        <Box pl={2} minHeight={50} d="inline-block">
          <Heading as="h2" size="xs" mt={0} pt={0} fontWeight={600}>
            {node.name}
          </Heading>
          {!!node.place && (
            <>
              <Text
                d="inline-block"
                fontSize="0.8rem"
                mr={2}
              >
                {`Por`}
              </Text>
              <Tag
                minHeight="0.9rem"
                rounded="full"
                my={1}
                fontSize="0.7rem"
                variantColor="red"
              >
                {node.place.name}
              </Tag>
            </>
          )}
        </Box>
        {tasted !== null && (
          <Box float="right" d="inline-block">
            <Button
              leftIcon={tasted ? IoMdCheckboxOutline : MdCheckBoxOutlineBlank}
              variant="outline"
              variantColor={tasted ? 'green' : 'gray'}
              size="xs"
            >
              {tasted ? 'Probada :)' : 'Aún no :('}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BurgerComponent;
