import { useState, useEffect } from "react";
import fp from "fingerprintjs2";
import hash from "object-hash";
import firebase from '../../plugins/gatsby-plugin-firebase';
import { getFingerPrint } from "../util/firebaseFunctions";
const { extendMoment } = require("moment-range");
const Moment = require("moment-timezone");

const moment = extendMoment(Moment);
moment.tz.setDefault("America/Bogota");

export const cleanData = f => {
  for (let key in f) {
    if (f[key] === null || f[key] === undefined || f[key] instanceof Error) {
      delete f[key];
    }
    if (Array.isArray(f[key])) {
      f[key] = f[key].join(", ");
    }
    if (
      (typeof f[key] === "string" || f[key] instanceof String) &&
      f[key].length === 0
    ) {
      delete f[key];
    }
    if (typeof f[key] === "boolean") {
      f[key] = `${f[key]}`;
    }
  }

  return hash(f);
};

const getCurrentFingerPrint = () =>
  new Promise(resolve => {
    fp.get(components => {
      resolve(components);
    });
});

const useFingerPrint = () => {
  const [fingerprint, setFingerprint] = useState(null);
  useEffect(() => {
    getCurrentFingerPrint().then(fp => {
      setFingerprint(cleanData(fp));
    });
  }, []);
  return fingerprint;
};

const isValidFingerprint = async (currentFingerPrint) => {
  const fingerPrint = await getFingerPrint(currentFingerPrint);
  if (fingerPrint === null) {
    return {
      isValid: true,
      isNew: true
    };
  }
  const currentDate = firebase.firestore.Timestamp.now().toDate();
  const updated = fingerPrint.updatedAt.toDate();
  const diff = moment(currentDate).diff(moment(updated), "minutes");
  let valid = false;
  let message = "";
  if (fingerPrint.votes === 1 && diff < 60) {
    message = "Ya registraste un voto con este dispositivo. Podrás votar nuevamente una hora después de tu último voto.";
  } else if (fingerPrint.votes === 2 && diff < 240) {
    message = "Ya registraste dos votos con este dispositivo. Podrás votar nuevamente cuatro horas después de tu último voto.";
  } else if (fingerPrint.votes === 3 && diff < 1440) {
    message = "Ya registraste tres votos con este dispositivo. Podrás votar nuevamente un día después de tu último voto.";
  } else if (fingerPrint.votes >= 4) {
    message = "Ya registraste cuatro votos con este dispositivo. No podrás votar nuevamente.";
  } else {
    valid = true;
  }
  return {
    isValid: valid,
    isNew: false,
    message: message,
  };
};

export {
  useFingerPrint,
  isValidFingerprint
};
