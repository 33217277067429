import React from "react";
import { totalVotesReference } from "../../util/firebaseFunctions";
import { Text, Box, Heading } from "@chakra-ui/core";

const BurgerVotes = ({ firebase, withBorder, ...props }) => {
  const [votes, setVotes] = React.useState(0);
  React.useEffect(() => {
    let unsubscribe;
    if (firebase) {
      unsubscribe = totalVotesReference(firebase)
        .onSnapshot((snap) => {
          setVotes(snap.data().count);
        }, (e) => console.warn(e));
    }
    return () => unsubscribe && unsubscribe();
  }, [firebase]);
  return (
    <Box
      d="table-cell"
      px={4}
      mr={4}
      borderRightWidth={withBorder ? 1 : 0}
      borderColor="rgba(255,255,255,0.3)"
      {...props}
    >
      <Heading as="h4" fontWeight={200} size={["md", "lg"]}>
        {"Total votos registrados:"}
      </Heading>
      <Text d="inline-block" mr={1} fontSize={["3xl", "4xl"]} fontWeight={500}>
        {votes}
      </Text>
    </Box>
  );
};

BurgerVotes.defaultProps = {
  withBorder: true,
};

export default BurgerVotes;
