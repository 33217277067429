import React from "react";
import * as PropTypes from "prop-types";
import firebase from "../../../plugins/gatsby-plugin-firebase";
import { useUser } from "../pilots/util";
import { useMyVote } from "../../util/firebaseFunctions";
import BurgerLayout from "../layout/burguerLayout";
import BurguerHeader from "./header";
// import VideoBurguer from "./video";
import BurgerStatus from "./burgerStatus";
import { MainHeading } from "../headings";
import { SimpleGrid } from "@chakra-ui/core";
import BurgerComponent from "./burgerComponent";
import BurgerPopup from "./burgerPopup";
import { actions, GlobalDispatchContext } from "../../context/GlobalContextProvider";

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const BurgerPage = ({ burgers, burger, navigate }) => {
  const dispatch = React.useContext(GlobalDispatchContext);
  const user = useUser(firebase);

  const [node, setNode] = React.useState(null);
  React.useEffect(() => {
    if (user) {
      setTimeout(() => setNode(burger), 500);
    } else if (burger) {
      dispatch({
        type: actions.setDialogMessage,
        message: "Regístrate o inicia sesión antes de votar por tu hamburguesa favorita"
      });
    }
  }, [burger]);

  const handleChangeNode = (theNode) => {
    if (!theNode && !!burger) {
      navigate("/rolda-burger");
    } else {
      setNode(theNode);
    }
  };

  const vote = useMyVote(user);

  const [states, setStates] = React.useState({
    message: '¡Vota por esta hamburguesa!',
    first: true,
    electedFirst: false,
    second: true,
    electedSecond: false,
    third: true,
    electedThird: false,
  });

  React.useEffect(() => {
    if (vote && node) {
      let first = false;
      let electedFirst = false;
      let second = false;
      let electedSecond = false;
      let third = false;
      let electedThird = false;
      let thisOne = null;
      let message = '¡Vota por esta hamburguesa!';
      if (vote.first) {
        first = true;
        if (vote.first === node.id) {
          thisOne = 'primer lugar';
          electedFirst = true;
        }
      }
      if (vote.second) {
        second = true;
        if (!thisOne && vote.second === node.id) {
          thisOne = 'segundo lugar';
          electedSecond = true;
        }
      }
      if (vote.third) {
        third = true;
        if (!thisOne && vote.third === node.id) {
          thisOne = 'tercer lugar';
          electedThird = true;
        }
      }
      if (thisOne) {
        message = `Votaste por esta hamburguesa para el ${thisOne}`;
        first = true;
        second = true;
        third = true;
      } else if (first && second && third) {
        message = '¡Ya elegiste tus tres hamburguesas!';
      }
      setStates({
        first,
        second,
        third,
        message,
        electedFirst,
        electedSecond,
        electedThird,
      });
    } else {
      setStates({
        message: '¡Vota por esta hamburguesa!',
        first: false,
        second: false,
        third: false,
        electedFirst: false,
        electedSecond: false,
        electedThird: false,
      });
    }
  }, [node, vote]);

  const [nodes, setNodes] = React.useState(null);
  React.useEffect(() => {
    setNodes(shuffle(burgers));
  }, [burgers]);

  return (
    <BurgerLayout
      headContent={
        <>
          <BurguerHeader/>
          <BurgerStatus firebase={firebase} />
        </>
      }
    >
      <MainHeading size="xl" mt={4} pt={0}>
        Vota por la mejor hamburguesa de Roldanillo
      </MainHeading>
      {!!nodes && (
        <SimpleGrid columns={[1, 2]} spacing={8}>
          {nodes.map((node) => (
            <BurgerComponent
              key={node.id}
              node={node}
              setNode={setNode}
              user={user}
              firebase={firebase}
            />
          ))}
        </SimpleGrid>
      )}
      <BurgerPopup
        node={node}
        setNode={handleChangeNode}
        user={user}
        firebase={firebase}
        states={states}
      />
    </BurgerLayout>
  );
};

BurgerPage.propTypes = {
  burgers: PropTypes.array.isRequired,
  burger: PropTypes.any,
  navigate: PropTypes.func.isRequired,
};

BurgerPage.defaultProps = {
  burger: null,
};

export default BurgerPage;
